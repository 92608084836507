import { StatusBar } from 'expo-status-bar';
import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Pressable } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function HamburgerMenu(props) {
    const [tab, setTab] = useState('HomeScreen')
    const [width, setWidth] = useState(window.innerWidth);

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => {props.setMenuActivated(true);}}>
        <View>
           <MaterialCommunityIcons
            name="menu"
            size={40}
            color="#212632"
          />
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    height: 50,
    width: 50,
    borderRadius: 10,
    backgroundColor: 'transparent',
    right: 100,
    top: 30
  },
});
