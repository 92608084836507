import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, TextInput } from 'react-native';
import { useFonts } from 'expo-font';
import FadeInText from './FadeInText';

export default function PhoneTitles(props) {
  const [FS, setFS] = useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const [FSS, setFSS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
  const styles = makeStyles(Math.min(window.innerWidth / 320, window.innerHeight / 640)); // pass in fontScale to the StyleSheet
      
  
  useEffect(() => {
    window.addEventListener('resize', changeTitleSize);
    return () => {
      window.removeEventListener('resize', changeTitleSize);
    }
  }, []);
      
  function changeTitleSize(){
    setFS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }
  
  return (
    <View style={{width: FS.width+ 400, justifyContent: 'space-evenly', flexDirection: 'row',bottom: 50, height: 100, }}>
      {!props.isFirstRender ? 
        <View style={{ width: FS.width+ 400, justifyContent: 'space-evenly', flexDirection: 'row',bottom: 50, height: 100, }}>
          <FadeInText notMenu duration={5000} style={{fontSize: 13 * FSS,  color: '#e6e6e6', width: 600,textAlign: 'center', }}>
            <Text>
              Whether you are looking for just something close or something popular, we have it all. 
            </Text>
          </FadeInText>
          <FadeInText notMenu duration={5000} style={{fontSize: 13 * FSS,  color: '#e6e6e6', width: 600,textAlign: 'center', }}>
            <Text >
              Gain access to live information coming straight from the source
              </Text>
          </FadeInText>
        </View>
      : null }
    </View>
  );
}

const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 20 * fontScale, 
    color: 'white'
  },
});
