import * as React from 'react';
import {
  Text,
  View,
  StyleSheet,
  Image,
  Animated,
  ScrollView,
  Dimensions,
  ImageBackground,
  TouchableOpacity
} from 'react-native';

const { width } = Dimensions.get('window');
const { height } = Dimensions.get('window');
// const height = width * 0.6;

export default function IphoneCarousel(props){
  const [active, setActive] = React.useState(0)
  const [animation] = React.useState(new Animated.Value(0));
  const inputRange = [0, 1];
  const outputRange = [.05, 1];
  const scale = animation.interpolate({inputRange, outputRange})

  const images = [
    {uri: require('../assets/listLight.png')},
    {uri: require('../assets/mapDark.png')},
    {uri: require('../assets/mapLight.png')},
    {uri: require('../assets/listDark.png')}
  ];

  React.useEffect(() => {
    Animated.timing(animation, {
      toValue: 1,
      useNativeDriver: true,
      duration: 1000
    }).start();
  },[])


  //-50 is so the slider does not update instantly
  const change = ({ nativeEvent }) => {
    const slide = Math.ceil(
      (nativeEvent.contentOffset.x - 200) / nativeEvent.layoutMeasurement.width
    );
    if (slide !== active) {
      setActive(slide);
    }
  };



  return (
    <Animated.View style={[ styles.animatedStyles , {opacity: props.fadeAnim}, {transform: [{scale}]}]}>
      <ScrollView
        snapToInterval={width}
        decelerationRate="fast"
        horizontal
        showsHorizontalScrollIndicator={false}
        onScroll={change}
        scrollEventThrottle={16}
        style={{ width:  width , height: 650, borderBottomLeftRadius: 20,  borderBottomRightRadius: 20, borderTopRightRadius: 10 , borderTopLeftRadius:  10}}>
        {
          images.map((image, index) => (
            <View style={{ width: width, justifyContent: 'center', alignItems: 'center'}}>
            <Image
              style={{height: 600, width: 300}}
              source={image.uri}>
            </Image>
            </View>
          ))
        }
      </ScrollView>


      <View
        style={{
          flexDirection: 'row',
          position: 'absolute',
          alignSelf: 'center',
          bottom:  20,
        }}>
          {images.map((i, k) => (
            <Text
            key={i.id}
              style={
                k == active
                  ? styles.activeCorousel
                  : styles.carousel
              }>
              ⬤
            </Text>
          ))}
      </View>

    </Animated.View>

);
}

const styles = StyleSheet.create({
  animatedStyles:{
    justifyContent: 'center', alignItems: 'center' , top: 25, 
  }, 
  button: {
    height: 70,
    width: 200,
    backgroundColor: 'red',
    marginBottom: 20,
    borderRadius: 10,
  },
  btn: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnText: {
    color: '#fff',
    fontSize: 25,
  },
  carousel: {
    color: 'white',
    fontSize: width / 35,
    opacity: 0.5,
  },
  activeCorousel: {
    color: '#FF1180',
    fontSize: width / 35,
  },
  detailsContainer: {
    flex: 2,
    backgroundColor: '#272933',
  },
  starRating: {
    color: 'white',
    fontSize: 20,
  },

  distanceText: {
    color: 'white',
    fontSize: 20,
    paddingLeft: 5,
  },
  barName: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 40,
    paddingLeft: 20,
  },
  barCategory: {
    color: 'white',
    fontSize: 20,
    paddingLeft: 20,
  },
  staticDetails: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 20,
  },
  dependentDetails: {
    color: 'grey',
    fontSize: 20,
    paddingLeft: 20,
  },
  dot: {
    color: 'white',
    fontSize: 40,
    paddingLeft: 10,
    paddingRight: 10,
  },
  money: {
    color: 'white',
    fontSize: 25,
  },
  navigationImage: {
    height: 30,
    width: 30,
    transform: [{ rotate: '45deg' }],
  },
  firstImage: {
    borderBottomLeftRadius: 10,
    borderTopLeftRadius: 20
  },
  lastImage: {
    borderBottomRightRadius: 10,
    borderTopRightRadius: 20
  }
});
