import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, TextInput, TouchableOpacity, ActivityIndicator, Image } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export default function AppStoreButton(props) {
  const [FS, setFS] = useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const [FSS, setFSS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
  const styles = makeStyles(Math.min(window.innerWidth / 320, window.innerHeight / 640)); // pass in fontScale to the StyleSheet
      
  
  useEffect(() => {
    window.addEventListener('resize', changeTitleSize);
    return () => {
      window.removeEventListener('resize', changeTitleSize);
    }
  }, []);
      
  function changeTitleSize(){
    setFS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
    setFSS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
  }



  const width = FS.width < 900 ? 30 : 35
  
  return (
    <View style={{flexDirection: 'row'}}>
    <TouchableOpacity style={{alignItems: 'center', height: FSS * 40, width: FSS * 140, backgroundColor: 'black', flexDirection: 'row', borderWidth: 1, borderColor: 'grey', borderRadius: 10, justifyContent: 'center'}}>
        <View style={{position: 'absolute', left: 10}}>
        <MaterialCommunityIcons
            name="apple"
            size={FSS * width}
            color="white"
        />
        </View>
        <View style={{position: 'absolute', right: FS.width < 900 ? 10 : 20}}>
            <Text style={{color: 'white', fontSize: FSS * 10}}>Download on the</Text>
            <Text style={{color: 'white', fontSize: FSS * 18}}>App Store</Text>
        </View>
    </TouchableOpacity>
    <View style={{left: FS.width < 900 ? 20 : 80, backgroundColor: '#2E313E', height: FSS * 150, width:  FSS * 150, borderRadius: 10, justifyContent: 'center', alignItems: 'center'}}>
        <Image
          source={require('../assets/whiteQR.png')}
          style={{width: FSS * 145, height: FSS * 145}}
        />
    </View>
    </View>
  );
}

const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 20 * fontScale, 
    color: 'white'
  },
});
