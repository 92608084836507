import { StatusBar } from 'expo-status-bar';
import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Pressable } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFonts } from 'expo-font';
import { Audio } from 'expo-av';
import ProgressBar from './ProgressBar';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function Footer(props){
  const [title, setTitle] = useState("Jacoozy")
  const [isPlaying, setIsPlaying] = useState(false)
  const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})

  useEffect(() => {
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  function changeTitleSize(){
    setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }

  
  return(
    <View style={{width: FSS.width, height: 200, borderTopColor: '#F60081', borderTopWidth: StyleSheet.hairlineWidth, backgroundColor: '#212632', justifyContent: 'center', alignItems: 'center' }}>
      <TouchableOpacity onPress={() => {props.switchTabs('AboutScreen', false, false, true)}}>
        <Text style={{fontSize: 20, color: 'white', padding: 10}}>
          Privacy Policy
        </Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => {}}>
        <Text style={{fontSize: 20, color: 'white', padding: 10}}>
          Terms and Conditions
        </Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => props.switchTabs('ContactScreen',)}>
        <Text style={{fontSize: 20, color: 'white', padding: 10}}>
          Contact Us
        </Text>
      </TouchableOpacity>
    </View>
  )
}


