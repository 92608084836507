// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getDatabase} from "firebase/database"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCdS1r3LuYaxLmr-9ZKieQE2qnVzzgyIKg",
  authDomain: "jacoozy-b63e7.firebaseapp.com",
  databaseURL: "https://jacoozy-b63e7-default-rtdb.firebaseio.com",
  projectId: "jacoozy-b63e7",
  storageBucket: "jacoozy-b63e7.appspot.com",
  messagingSenderId: "980884095472",
  appId: "1:980884095472:web:b33d57617ab9fd3b599d35",
  measurementId: "G-2R699LGFN1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getDatabase(app);