import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, TouchableOpacity,Pressable, Image} from 'react-native';
import { useFonts } from 'expo-font';
import { useNavigation } from '@react-navigation/native';



export default function WebsiteHeader(props) {
  const navigation = useNavigation();
    const [fontsLoaded] = useFonts({
        'CG-Times Bold': require('./assets/cgtr65w.otf'),
      });
    const smallScreen = props.FSS < 900
  return (
    <Pressable style={{backgroundColor: 'transparent', position: 'absolute', top:20, left: 50, zIndex: 101, flexDirection: 'row'}}
    onPress={() => props.switchTabs('HomeScreen', false, true)}>
        <Image
          source={require('./assets/centeredGrey.png')}
          style={{height: props.FSS * smallScreen ? 75 : 50, width: props.FSS * smallScreen ? 50 :  35 ,top: 3}}
        />
        <Text style={{ fontSize: 40, color: '#212632', fontWeight: 'bold[3'}}>ludo</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({

});
