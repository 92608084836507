import { StatusBar } from 'expo-status-bar';
import  { useState, useEffect, useRef, useContext } from 'react'
import { useFonts } from 'expo-font';
import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Pressable, Animated } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function FadeInText(props) {
    const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
    const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
    const [fadeAnim] = useState(new Animated.Value(0));
    const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
    const [fontsLoaded] = useFonts({
        'CG-Times Bold': require('../assets/cgtr65w.otf'),
      });
    const style = { fontSize: 15 * FS, color: '#e6e6e6',width: FSS.width/3, textAlign: 'center',}

    useEffect(() => {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: props.duration,
      }).start();
    }, []);

      
    useEffect(() => {
      window.addEventListener('resize', changeTitleSize);
      return () => {
        window.removeEventListener('resize', changeTitleSize);
      }
    }, []);

    function changeTitleSize(){
      setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
      setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
    }
  
    return (
      <Animated.View
        style={{
            opacity: fadeAnim,
            borderBottomWidth: props?.notMenu ? 0 : props.tab == props.currentTab ? 1 : 0,
            borderBottomColor: 'white',
        }}
      >
        <Text style={props.notMenu ? style : styles.textStyle}>{props.children}</Text>
      </Animated.View>
    );
}

const makeStyles = fontScale => StyleSheet.create({
  container: {
    borderBottomColor: 'white',
    borderBottomWidth: 1
  },
  textStyle:{
    fontFamily: 'CG-Times Bold', 
    fontSize: 25 * fontScale, 
    color: 'white'
  },
  textStyle2:{
    fontSize: 15 * fontScale, 
    color: '#e6e6e6',
    width: 600,
    textAlign: 'center',
  }
  
  
});
