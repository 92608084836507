import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, TextInput, ActivityIndicator, TouchableOpacity } from 'react-native';
import { useFonts } from 'expo-font';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';

export default function BringLudoToMeForm(props) {
  const [FS, setFS] = useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const styles = makeStyles(Math.min(window.innerWidth / 320, window.innerHeight / 640)); // pass in fontScale to the StyleSheet
  const [firstName, onChangeFirstName] = useState('');
  const [lastName, onChangeLastName] = useState('');
  const [email, onChangeEmail] = useState('');
  const [location, onChangeLocation] = useState('');
  const [emailError, setEmailError] = useState(false)
  const [bigError, setBigError] = useState(false)
  const [emailSentSuccess, setEmailSentSuccess] = useState(false)
  const [emailSending, setEmailSending] = useState(false)
  const [isReadyToSend, setIsReadyToSend] = useState(false)
      
  
  useEffect(() => {
    window.addEventListener('resize', changeTitleSize);
    return () => {
      window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  useEffect(() => {
    if(!verifyForm()) return setIsReadyToSend(false)
    return setIsReadyToSend(true)
  }, [email, firstName, lastName, location]);
      
  function changeTitleSize(){
    setFS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }

  function onSubmit(){
    const catchEmAll = async () => {
      try{
        setEmailSending(true)
        await fetch('https://ludo-api-20z4.onrender.com/signUp', {
          method: 'POST',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            passphrase: "huyter",
            name: firstName + ' ' + lastName,
            email: email,
            location: location,
            subject: "Bring Ludo to me!",
            content: "Someone wants Ludo in there area! from the website"
          }),
        })
        setEmailSending(false)
        setEmailSentSuccess(true)
        setBigError(false)
        onChangeFirstName('')
        onChangeLastName('')
        onChangeEmail
        onChangeLocation
      } catch(e) {
        console.log("error when sending email", e)
        setBigError(true)
        setEmailSending(false)
        setEmailSentSuccess(false)
      }
    }
    catchEmAll()
}

function verifyForm(){
  if(firstName === '') return false;
  if(lastName === '') return false;
  if(email === ''){
    return false
  } else if(!validate(email)) return false;
  if(location === '') return false;

  return true
}


const validate = (text) => {
  console.log(text);
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  if (reg.test(text) === false) {
    console.log("Email is Not Correct");
    setEmailError(true)
    return false;
  } 
  else {
    setEmailError(false)
    console.log("Email is Correct");
    return true;
  }
}

  const width = FS.width < 900 ? 150 : 200
  
  return (
    <View style={{alignItems: 'center',}}>
       <Text style={{fontSize: 14, color: 'white', position: 'absolute', top: -20}}>{bigError && 'Sorry, there was an issue submitting your form'}</Text>
      <View style={{flexDirection: 'row', width: 500, justifyContent: 'space-evenly', paddingBottom: 30}}>
        <TextInput
          style={{width: width, height: 40, borderRadius: 10, backgroundColor: '#e6e6e6', padding: 10, color: '#F60081'}}
          onChangeText={onChangeFirstName}
          value={firstName}
          placeholder='First Name'
          placeholderTextColor={'#F60081'}
        />
        <TextInput
          style={{width: width, height: 40, borderRadius: 10, backgroundColor: '#e6e6e6', padding: 10, color: '#F60081'}}
          onChangeText={onChangeLastName}
          value={lastName}
          placeholder='Last Name'
          placeholderTextColor={'#F60081'}
        />
      </View>
      <View style={{flexDirection: 'row', width: 500, justifyContent: 'space-evenly'}}>
        <View>
          <Text style={{fontSize: 14, color: 'white', position: 'absolute', top: -20}}>{emailError && 'Please enter a valid email'}</Text>
          <TextInput
            style={{width: width, height: 40, borderRadius: 10, backgroundColor: '#e6e6e6', padding: 10, color: '#F60081'}}
            onChangeText={onChangeEmail}
            value={email}
            placeholder='Email'
            placeholderTextColor={'#F60081'}
          />
        </View>
        <TextInput
          style={{width: width, height: 40, borderRadius: 10, backgroundColor: '#e6e6e6', padding: 10, color: '#F60081'}}
          onChangeText={onChangeLocation}
          value={location}
          placeholder='City, State'
          placeholderTextColor={'#F60081'}
        />
      </View>
      {emailSending && <ActivityIndicator size={100} style={{position: 'absolute', top: 20}} color={'grey'}/>}
      <TouchableOpacity activeOpacity={!isReadyToSend ? .2 : .2} disabled={!isReadyToSend} onPress={() => !isReadyToSend ? null : onSubmit()}
        style={{width: 300, height: 50, backgroundColor: '#F60081', justifyContent: 'center', alignItems: 'center', borderRadius: 10, opacity: !isReadyToSend ? .2 : 1, top: 30}}>
        <Text style={{color: 'white', fontSize: 18}}>Submit</Text>
      </TouchableOpacity>
    </View>
  );
}

const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 20 * fontScale, 
    color: 'white'
  },
});
