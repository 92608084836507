import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Dimensions } from 'react-native';
import { useFonts } from 'expo-font';
import { useEffect, useState, useRef } from 'react';
import { WebView } from 'react-native-webview';

const { height, width } = Dimensions.get('window');

export default function About(props) {
  const [fontsLoaded] = useFonts({
        'CG-Times Bold': require('../assets/cgtr65w.otf'),
  });
  const [FS, setFS] = useState(Math.min(window.innerWidth / 640, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const styles = makeStyles(FS); // pass in fontScale to the StyleSheet




  useEffect(() => {
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  function changeTitleSize(){
    setFS(Math.min(window.innerWidth / 640, window.innerHeight / 640))
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }
  const resourceType = 'file';
  if(props.privacyPolicy){
  return (
  <div width={FSS.width} height={FSS.height}>
    <iframe src={require('../assets/pp.pdf')} width={FSS.width} height={FSS.height} />
  </div>
  );
  } else {
    return(
      <View style={styles.container}>
        <Text style={{textAlign: 'center' ,fontFamily: 'CG-Times Bold', fontSize: 50 * FS, color: 'white',paddingTop: 200,width: FSS.width/1.1}}>About</Text>
      </View>
    )
  }
}

const makeStyles = fontScale => StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 50 * fontScale, 
    color: 'white',
    paddingTop: 200,
    width: width / 1.3
  },
});
