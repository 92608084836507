import { StatusBar } from 'expo-status-bar';
import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Pressable } from 'react-native';
import RootContainerNavigation from './RootContainerNavigation';
import { useFonts } from 'expo-font';
import { useNavigation } from '@react-navigation/native';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function WebsiteTabs(props) {
    const navigation = useNavigation();
    const [tabIndex, setTabIndex] = useState(0);
    const [fontsLoaded] = useFonts({
        'CG-Times Bold': require('./assets/cgtr65w.otf'),
      });

    useEffect(() => {
        if(props.headerTriggerd) switchTabs(0, 'HomeScreen')
    },[props.headerTriggerd])

    function switchTabs(index, screen){
        setTabIndex(index)
        props.switchTabs(screen)
        props.setHeaderTriggered(false)
    }


  return (
    <View style={styles.container}>
        <View style={{ paddingRight:10}}>
            <Pressable onPress={() => switchTabs(0,'HomeScreen')}>
                <Text style={{fontSize: 24, color: '#212632',fontWeight: 'bold', borderBottomColor: '#212632',fontWeight: 'bold', borderBottomWidth: tabIndex === 0 ? 1 : 0}}>HOME</Text>
            </Pressable>
        </View>
         <View style={{ paddingRight:10}}>
            <Pressable onPress={() => {switchTabs(1,'AboutScreen'); props.setImageOrVideo(false)}}>
                <Text style={{fontSize: 24, color: '#212632',fontWeight: 'bold', borderBottomColor: '#212632',fontWeight: 'bold', borderBottomWidth: tabIndex === 1 ? 1 : 0,}}>ABOUT</Text>
            </Pressable>
        </View>
         <View style={{ paddingRight:10}}>
            <Pressable onPress={() => {switchTabs(2, 'ContactScreen'); props.setImageOrVideo(false)}}>
                <Text style={{fontSize: 24,  color: '#212632',fontWeight: 'bold', borderBottomColor: '#212632',fontWeight: 'bold', borderBottomWidth: tabIndex === 2 ? 1 : 0,}}>CONTACT</Text>
            </Pressable>
        </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    position:'absolute',
    width: 375,
    right: 0,
    top: -200,
  },
});
