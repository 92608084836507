import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, TextInput, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useFonts } from 'expo-font';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';

export default function AddBarToLudoForm(props) {
  const [FS, setFS] = useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const styles = makeStyles(Math.min(window.innerWidth / 320, window.innerHeight / 640)); // pass in fontScale to the StyleSheet
  const [firstName, onChangeFirstName] = useState('');
  const [lastName, onChangeLastName] = useState('');
  const [email, onChangeEmail] = useState('');
  const [location, onChangeLocation] = useState('');
  const [barName, onChangeBarName] = useState('');
  const [emailError, setEmailError] = useState(false)
  const [bigError, setBigError] = useState(false)
  const [emailSentSuccess, setEmailSentSuccess] = useState(false)
  const [emailSending, setEmailSending] = useState(false)
  const [isReadyToSend, setIsReadyToSend] = useState(false)
      
  
  useEffect(() => {
    window.addEventListener('resize', changeTitleSize);
    return () => {
      window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  useEffect(() => {
    if(!verifyForm()) return setIsReadyToSend(false)
    return setIsReadyToSend(true)
  }, [email, firstName, lastName, location, barName]);
      
  function changeTitleSize(){
    setFS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }

  function onSubmit(){
      const catchEmAll = async () => {
        try{
          setEmailSending(true)
          await fetch('https://ludo-api-20z4.onrender.com/sendEmail', {
            method: 'POST',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              passphrase: "huyter",
              name: firstName + ' ' + lastName,
              establishmentName: barName,
              email: email,
              location: location,
              subject: "contact from website",
              content: "Someone has contacted you about getting their Bar on Ludo from the website!"
            }),
          })
          setEmailSending(false)
          setEmailSentSuccess(true)
          setBigError(false)
          onChangeFirstName('')
          onChangeLastName('')
          onChangeEmail('')
          onChangeLocation('')
          onChangeBarName('')
        } catch(e) {
          console.log("error when sending email", e)
          setBigError(true)
        }
      }
      catchEmAll()
  }

  function verifyForm(){
    if(firstName === '') return false;
    if(lastName === '') return false;
    if(email === ''){
      return false
    } else if(!validate(email)) return false;
    if(location === '') return false;
    if(barName === '') return false;

    return true
  }


  const validate = (text) => {
    console.log(text);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      console.log("Email is Not Correct");
      setEmailError(true)
      return false;
    } 
    else {
      setEmailError(false)
      console.log("Email is Correct");
      return true;
    }
  }

  const width = FS.width < 900 ? 150 : 200
  
  return (
    <View style={{alignItems: 'center',}}>
      <View style={{flexDirection: 'row', width: 500, justifyContent: 'space-evenly', paddingBottom: 30}}>
        <TextInput
          style={{width: width, height: 40, borderRadius: 10, backgroundColor: '#e6e6e6', padding: 10, color: '#F60081'}}
          onChangeText={onChangeFirstName}
          value={firstName}
          placeholder='First Name'
          placeholderTextColor={'#F60081'}
        />
        <TextInput
          style={{width: width, height: 40, borderRadius: 10, backgroundColor: '#e6e6e6', padding: 10, color: '#F60081'}}
          onChangeText={onChangeLastName}
          value={lastName}
          placeholder='Last Name'
          placeholderTextColor={'#F60081'}
        />
      </View>
      <View style={{flexDirection: 'row', width: 500, justifyContent: 'space-evenly', paddingBottom: 30}}>
        <View>
          <Text style={{fontSize: 14, color: 'white', position: 'absolute', top: -20}}>{emailError && 'Please enter a valid email'}</Text>
          <TextInput
            style={{width: width, height: 40, borderRadius: 10, backgroundColor: '#e6e6e6', padding: 10, color: '#F60081'}}
            onChangeText={onChangeEmail}
            value={email}
            placeholder='Email'
            placeholderTextColor={'#F60081'}
          />
        </View>
        <TextInput
          style={{width: width, height: 40, borderRadius: 10, backgroundColor: '#e6e6e6', padding: 10, color: '#F60081'}}
          onChangeText={onChangeLocation}
          value={location}
          placeholder='City, State'
          placeholderTextColor={'#F60081'}
        />
      </View>
      <View style={{justifyContent: 'center', paddingBottom: 30}}>
        <TextInput
            style={{width: 200, height: 40, borderRadius: 10, backgroundColor: '#e6e6e6', padding: 10, color: '#F60081'}}
            onChangeText={onChangeBarName}
            value={barName}
            placeholder='Name of Establishment'
            placeholderTextColor={'#F60081'}
          />
      </View>
      {emailSending && <ActivityIndicator size={100} style={{position: 'absolute', top: 20}} color={'grey'}/>}
      <TouchableOpacity activeOpacity={!isReadyToSend ? .2 : .2} disabled={!isReadyToSend} onPress={() => !isReadyToSend ? null : onSubmit()}
        style={{width: 300, height: 50, backgroundColor: '#F60081', justifyContent: 'center', alignItems: 'center', borderRadius: 10, opacity: !isReadyToSend ? .2 : 1 }}>
        <Text style={{color: 'white', fontSize: 18}}>Submit</Text>
      </TouchableOpacity>
    </View>
  );
}

const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 20 * fontScale, 
    color: 'white'
  },
});
