import * as React from 'react';
import { View, Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeTab from './HomeTab';
import Contact from './Tabs/Contact';
import About from './Tabs/About'
import RootScreen from './RootScreen'

const Stack = createNativeStackNavigator();

function RootContainer() {
  return (
    <NavigationContainer
      documentTitle={{
        formatter: (options, route) =>
          // `MyAppName - ${options?.title ?? route?.name}`
          'Ludo'
      }}
    >
      <Stack.Navigator>
        <Stack.Screen name="RootScreen" component={RootScreen} options={{ headerShown: false, }}/>
        <Stack.Screen name="HomeScreen" component={HomeTab} options={{ headerShown: false, }}/>
        <Stack.Screen name="ContactScreen" component={About} options={{ headerShown: false, }}/>
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default RootContainer;