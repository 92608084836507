import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import Home from './Tabs/Home';
import WebsiteHeader from './WebsiteHeader'
import WebsiteTabs from './WebsiteTabs'
import {isMobile} from 'react-device-detect';


export default function HomeTab() {
  return (
    <View style={styles.container}>
        <Home/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
