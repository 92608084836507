// import { StatusBar } from 'expo-status-bar';
// import { StyleSheet, Text, View, useWindowDimensions, Dimensions, Linking, Image, Animated, Easing} from 'react-native';
// import { useFonts } from 'expo-font';
// import { useEffect, useState, useRef, useCallback } from 'react';
// import Svg, { Path } from 'react-native-svg';
// import { Video, ResizeMode } from 'expo-av';
// import { ScrollView, TextInput } from 'react-native-web';
// // import Carousel from 'react-native-snap-carousel';
// import IphoneCarousel from '../common/IphoneCarousel';
// import Footer from '../common/Footer';
// import BringLudoToMeForm from '../common/BringLudoToMeForm';
// import AddBarToLudoForm from '../common/AddBarToLudoForm';
// import PhoneTitles from '../common/PhoneTitles';
// import AppStoreButton from '../common/AppStoreButton';
// import Carousel from '../common/Carousel';

// const { height, width } = Dimensions.get('window');
// // const fontScale = c

// export default function Home(props) {
//   const { setIsFirstRender, isFirstRender, imageOrVideo} = props;
//   const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
//   const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
//   const [firstRender, setFirstRender] = useState(true)
//   const [isReady, setIsReady] = useState(false)
//   const [, updateState] = useState();
//   const forceUpdate = useCallback(() => updateState({}), []);
//   const [fontsLoaded] = useFonts({
//         'CG-Times Bold': require('../assets/cgtr65w.otf'),
//       });
//   const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
//   const [slideShow, setSlideShow] = useState(false)
//   const [fadeAnim] = useState(new Animated.Value(0));
//   const smallScreen = FSS.width < 900;
//   // const [animation] = useState(new Animated.Value(0));
//   // const inputRange = [0, 1];
//   // const outputRange = [1, 0.05];
//   // const scale = animation.interpolate({inputRange, outputRange});

//   useEffect(() => {
//     triggerSlideshow()
//     // getSong("11dFghVXANMlKmJXsNCbNl")
//     window.addEventListener('resize', changeTitleSize);
//     return () => {
//         window.removeEventListener('resize', changeTitleSize);
//     }
//   }, []);

//   useEffect(() => {
//     if(firstRender) forceUpdate(); setFirstRender(true);console.log("wtf")
//   }, []);

//   function changeTitleSize(){
//     setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
//     setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
//   }

//   const renderItem = ({item, index}) => {
//     return (
//         <View style={{backgroundColor:'green'}}>
//             <Text style={{}}>hello </Text>
//         </View>
//     );
//   }

//   function triggerSlideshow(){
//     console.log("we are here")
//     setSlideShow(true)
//     Animated.timing(fadeAnim, {
//       toValue: 1,
//       duration: 5000,
//       Easing: Easing
//     }).start();
//   }


//   return (
//     <View style={{ alignItems: 'center', justifyContent: 'center', }}>
//       {!smallScreen ? 
//       <View style={{height: FSS.height, width: FSS.width, justifyContent: 'center', alignItems: 'center'}}>
//         <View style={{flexDirection: 'row'}}>
//           <View style={{top: FS * 50, marginRight: FS * 100}}>
//             <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: 'white', paddingBottom: 20,width: FS * 500, }}>Designed to <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: '#F60081'}}>take out</Text> the guesswork of <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: '#F60081'}}>going out</Text>.</Text>
//             <Text style={{fontSize: 15 * FS, color: 'white', paddingBottom: 20,width: FS * 500,}}>Click below to download the app or scan the QR code</Text>
//             <View style={{top: FS * 50}}>
//               <AppStoreButton/>
//             </View>
//           </View>
//           <Carousel/>
//         </View>
//       </View>
//       : 
//       <View style={{justifyContent: 'center', alignItems: 'center', height: FSS.height, width: FSS.width, top: FS*200}}>
//           <View style={{justifyContent: 'center', alignItems: 'center' }}>
//             <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: 'white', paddingBottom: 20, width: FS * 200}}>Designed to <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: '#F60081'}}>take out</Text> the guesswork of <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: '#F60081'}}>going out</Text>.</Text>
//             <Carousel/>
//            <Text style={{fontSize: 15 * FS, color: 'white', paddingBottom: 20,}}>Click below to download the app or scan the QR code</Text>
//             <View style={{}}>
//               <AppStoreButton/>
//             </View>
//         </View>
//       </View>
//     }
//       {/* {imageOrVideo && (!slideShow) && FSS.width > 900 ? // if video
//         <Video
//           style={{width: FSS.width, height: FSS.height, resizeMode: 'contain'}}
//           source={ require('../assets/almostFinalLongAnimation.mp4')}//iphone color to match background = #212631. Desktop = #212632
//           resizeMode={ResizeMode.CONTAIN}
//           shouldPlay={isFirstRender}
//           isMuted
//           videoStyle={{width: FSS.width, height: FSS.height}}
//           onLoadStart={() => setTimeout(() => {
//               setIsFirstRender(false);
//           }, 6500)}
//         />
//       : //else image
      
//       smallScreen ? //if phone
//           <View style={{height: FSS.height, top: 25, }}>
//             <IphoneCarousel fadeAnim={fadeAnim} triggerSlideshow={triggerSlideshow}/>
//           </View>
//       :
//       <ScrollView style={{height: FSS.height}}>
//         <Image
//           source={require('../assets/previewed(3).png')}
//           style={{width: smallScreen ? FSS.width :FSS.width-200, height: smallScreen ? FSS.height-200 : FSS.height-100, top: 75}}
//         />
//       </ScrollView>
//       } */}

//       {/* Beginning of top 4 simulator captions - 4 sims should be map, list, friends, profile */}
//       {/* <PhoneTitles isFirstRender={isFirstRender}/> dont put anything aobut acceess living info here, so underneath we can have a section about "Gain access to real live information describing how your experience will be" with photos of bar chat and venueDetails page */}

//       <View style={{bottom: smallScreen ? 0 : 25, }}>
//       {/* Beginning of Is Ludo near you? */}
//         <View style={{justifyContent: 'center', alignItems: 'center', width: FSS.width, backgroundColor: '#2E313E', flexDirection: 'row'}}> 
//         <Image
//             source={require('../assets/singleSim.png')}
//             style={{width: smallScreen ? 0 : 350, height: smallScreen ? 0 : 800, left: FS * 200 }}
//           />
//         <View>
//         <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: 'white', marginBottom: 10, textAlign: 'center'}}>Is Ludo near you?</Text>
//         <Animated.View style={{justifyContent: 'center', alignItems: 'center'}}>
//             <Text style={{fontSize: 15 * FS,  color: '#e6e6e6', width: FSS.width < 400 ? FSS.width : FSS.width, maxWidth: 1000 ,textAlign: 'justify', paddingRight: 20, paddingBottom: 50}}>
//              If there is a location you would like to see on Ludo that is not 
//               provided on the list below, please add your information.
//             </Text>
//         </Animated.View>
//         <View style={{flexDirection: 'row',justifyContent: 'center', alignItems: 'center', width: FSS.width,}}> 
//         <View style={{}}>

//           <View style={{flexDirection: 'row', justifyContent: 'space-evenly', paddingBottom: 50}}>
//             <View style={{paddingRight: 20}}>
//               <Text style={{fontSize: 15 * FS, color: 'white', paddingBottom: 10, textDecorationLine: 'underline'}}>
//                 Locations
//               </Text>
//               <Text style={{fontSize: 15 * FS, color: '#e6e6e6'}}>
//                 Charleston, SC
//               </Text>
//             </View>

//             <View style={{}}>
//               <Text style={{fontSize: 15 * FS, color: 'white', paddingBottom: 10,textDecorationLine: 'underline'}}>
//                 Coming soon...
//               </Text>
//               <Text style={{fontSize: 15 * FS, color: '#e6e6e6'}}>
//                 Wilmington, NC
//               </Text>
//               <Text style={{fontSize: 15 * FS, color: '#e6e6e6'}}>
//                 Charlotte, NC
//               </Text>
//               <Text style={{fontSize: 15 * FS, color: '#e6e6e6'}}>
//                 Raleigh, NC
//               </Text>
//               <Text style={{fontSize: 15 * FS, color: '#e6e6e6'}}>
//                 Greensboro, NC
//               </Text>
//             </View>
//           </View>

          
//         </View>
//         {/* Beginning of Bring Ludo to me and subtitle */}
//         <View style={{paddingBottom: 50, justifyContent: 'center', alignItems: 'center', padding: 20, marginLeft: FS * 50}}>
//           {/* <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: 'white',textAlign: 'center', paddingBottom: 10 }}>Bring Ludo to me!</Text> */}
//           {/* <Text style={{fontSize: 15 * FS,  color: '#e6e6e6', width: FSS.width < 400 ? FSS.width : 600, maxWidth: 1550 ,textAlign: 'center', paddingLeft: 20, paddingRight: 20 }}>
//             The more people that sign up in an area, the quicker Ludo will arrive!
//           </Text> */}
//           {/* Beginning of Bring Ludo to me form */}
//           <View style={{paddingBottom: 50,}}>
//             <BringLudoToMeForm/>
//           </View>
//           {/* End of Bring Ludo to me form */}
//         </View>
//         {/* End of Bring Ludo to me and subtitle */}
//       </View>
//       </View>
//       </View>
//       {/* End of Is Ludo near you? */}

 
//      {/* <View style={{backgroundColor: '#2E313E', width: FSS.width, justifyContent: 'center', alignItems: 'center' }}> */}
      
      
//         <View style={{flexDirection: 'row',justifyContent: 'center', alignItems: 'center', }}> 

//           <View style={{paddingBottom: 30, justifyContent: 'center', alignItems: 'center'}}>
//             <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: 'white', paddingBottom: 10, textAlign:'center', maxWidth: smallScreen ? 400 : 1000}}>Looking to add your establishment to Ludo?</Text>
//             <Text style={{fontSize: 18 * FS,  color: '#e6e6e6', width: FSS.width < 400 ? FSS.width : 600, maxWidth: smallScreen ? 500 : 800 ,textAlign: 'center', paddingBottom: 40, paddingLeft: 20, paddingRight: 20}}>
//              Contact us to learn about how Ludo can help increase foot traffic to your establishment. Lets get Ludo users to your business! 
//             </Text>
//             <AddBarToLudoForm/>
//           </View>

//           <Image
//             source={require('../assets/singleSim2.png')}
//             style={{width: smallScreen ? 0 : 350, height: smallScreen ? 0 : 800, left: 20}}
//           />

//         </View>


//       {/* </View> */}


//       </View>


//       <Footer/>
      

//     </View>
//   );
// }


// const makeStyles = fontScale => StyleSheet.create({
//   title: {
//     fontFamily: 'CG-Times Bold', 
//     fontSize: 100 * fontScale, 
//     color: '#F60081', 
//   },
//   name: {
//     fontFamily: 'CG-Times Bold', 
//     fontSize: 30 * fontScale,  
//     color: 'white',
//     padding: 5,
//   },
//   button: {
//     paddingRight: 20,
//     paddingLeft: 20,
//     padding: 15,
//     justifyContent: 'center',
//     alignItems: 'center',
//     backgroundColor: '#eafbf2',
//     borderRadius: 30,
//     top: 25
//   },
//   buttonSong: {
//     width: 375,
//     height: 50,
//     padding: 10,
//     alignItems: 'center',
//     backgroundColor: 'white',
//     borderRadius: 30,
//     flexDirection: 'row',
//     marginTop: 20,
//     alignSelf :'center'
//   },
//   buttonText: {
//     fontSize: 15,
//     fontFamily: 'CG-Times Bold', 
//   },
//   buttonSongText: {
//     fontSize: 20,
//     fontFamily: 'CG-Times Bold', 
//     marginLeft: 15
//   },
//   email: {
//     color: 'white', 
//     fontFamily: 'CG-Times Bold', 
//     position: 'absolute', 
//     bottom: 25, 
//     fontSize: 17
//   }
// });




























import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, useWindowDimensions, Dimensions, Linking, Image, Animated, Easing} from 'react-native';
import { useFonts } from 'expo-font';
import { useEffect, useState, useRef, useCallback } from 'react';
import Svg, { Path } from 'react-native-svg';
import { Video, ResizeMode } from 'expo-av';
import { ScrollView, TextInput } from 'react-native-web';
// import Carousel from 'react-native-snap-carousel';
import IphoneCarousel from '../common/IphoneCarousel';
import Footer from '../common/Footer';
import BringLudoToMeForm from '../common/BringLudoToMeForm';
import AddBarToLudoForm from '../common/AddBarToLudoForm';
import PhoneTitles from '../common/PhoneTitles';
import AppStoreButton from '../common/AppStoreButton';
import Carousel from '../common/Carousel';
import Swiper from 'react-native-web-swiper';

const { height, width } = Dimensions.get('window');
// const fontScale = c

export default function Home(props) {
  const { setIsFirstRender, isFirstRender, imageOrVideo, switchTabs} = props;
  const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const [firstRender, setFirstRender] = useState(true)
  const [isReady, setIsReady] = useState(false)
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [fontsLoaded] = useFonts({
        'CG-Times Bold': require('../assets/cgtr65w.otf'),
      });
  const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
  const [slideShow, setSlideShow] = useState(false)
  const [fadeAnim] = useState(new Animated.Value(0));
  const smallScreen = FSS.width < 900;
  // const [animation] = useState(new Animated.Value(0));
  // const inputRange = [0, 1];
  // const outputRange = [1, 0.05];
  // const scale = animation.interpolate({inputRange, outputRange});

  useEffect(() => {
    triggerSlideshow()
    // getSong("11dFghVXANMlKmJXsNCbNl")
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  useEffect(() => {
    if(firstRender) forceUpdate(); setFirstRender(true);console.log("wtf")
  }, []);

  function changeTitleSize(){
    setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }

  const renderItem = ({item, index}) => {
    return (
        <View style={{backgroundColor:'green'}}>
            <Text style={{}}>hello </Text>
        </View>
    );
  }

  function triggerSlideshow(){
    console.log("we are here")
    setSlideShow(true)
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 5000,
      Easing: Easing
    }).start();
  }


  return (
    <View style={{ alignItems: 'center', justifyContent: 'center', }}>
      {!smallScreen ? 
      <View style={{height: FSS.height, width: FSS.width, justifyContent: 'center', alignItems: 'center'}}>
        <View style={{flexDirection: 'row'}}>
          <View style={{top: FS * 50, marginRight: FS * 100}}>
            <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: 'white', paddingBottom: 20,width: FS * 500, }}>Designed to <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: '#F60081'}}>take out</Text> the guesswork of <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: '#F60081'}}>going out</Text>.</Text>
            <Text style={{fontSize: 15 * FS, color: 'white', paddingBottom: 20,width: FS * 500,}}>Click below to download the app or scan the QR code</Text>
            <View style={{top: FS * 50}}>
              <AppStoreButton/>
            </View>
          </View>
          <Carousel/>
        </View>
      </View>
      : 
      <View style={{justifyContent: 'center', alignItems: 'center', height: FSS.height, width: FSS.width, top: FS*125}}>
          <View style={{justifyContent: 'center', alignItems: 'center', }}>
            <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: 'white', paddingBottom: 20, width: FS * 300}}>Designed to <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: '#F60081'}}>take out</Text> the guesswork of <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: '#F60081'}}>going out</Text>.</Text>
            <Carousel/>
           <Text style={{fontSize: 15 * FS, color: 'white', paddingBottom: 20, width: FSS.width-50,}}>Click below to download the app or scan the QR code</Text>
            <View style={{right: FS * 5}}>
              <AppStoreButton/>
            </View>
        </View>
      </View>
    }
      {/* {imageOrVideo && (!slideShow) && FSS.width > 900 ? // if video
        <Video
          style={{width: FSS.width, height: FSS.height, resizeMode: 'contain'}}
          source={ require('../assets/almostFinalLongAnimation.mp4')}//iphone color to match background = #212631. Desktop = #212632
          resizeMode={ResizeMode.CONTAIN}
          shouldPlay={isFirstRender}
          isMuted
          videoStyle={{width: FSS.width, height: FSS.height}}
          onLoadStart={() => setTimeout(() => {
              setIsFirstRender(false);
          }, 6500)}
        />
      : //else image
      
      smallScreen ? //if phone
          <View style={{height: FSS.height, top: 25, }}>
            <IphoneCarousel fadeAnim={fadeAnim} triggerSlideshow={triggerSlideshow}/>
          </View>
      :
      <ScrollView style={{height: FSS.height}}>
        <Image
          source={require('../assets/previewed(3).png')}
          style={{width: smallScreen ? FSS.width :FSS.width-200, height: smallScreen ? FSS.height-200 : FSS.height-100, top: 75}}
        />
      </ScrollView>
      } */}

      {/* Beginning of top 4 simulator captions - 4 sims should be map, list, friends, profile */}
      {/* <PhoneTitles isFirstRender={isFirstRender}/> dont put anything aobut acceess living info here, so underneath we can have a section about "Gain access to real live information describing how your experience will be" with photos of bar chat and venueDetails page */}

      <View style={{top: smallScreen ? FS * 225 : null}}>
      {/* Beginning of Is Ludo near you? */}
        {!smallScreen ?
      <View style={{justifyContent: 'center', alignItems: 'center', width: FSS.width, backgroundColor: '#2E313E', flexDirection: 'row', alignSelf: 'center'}}> 
        <Image
            source={require('../assets/singleSimLight.png')}
            style={{width: smallScreen ? 0 : 350, height: smallScreen ? 0 : 800, left: FS * 200 }}
          />
        <View>
        <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: 'white', marginBottom: 10, textAlign: 'center'}}>Is Ludo near you?</Text>
        <Animated.View style={{justifyContent: 'center', alignItems: 'center'}}>
            <Text style={{fontSize: 15 * FS,  color: '#e6e6e6', width: FSS.width < 400 ? FSS.width : FSS.width, maxWidth: 1000 ,textAlign: 'justify', paddingRight: 20, paddingBottom: 50}}>
             If there is a location you would like to see on Ludo that is not 
              provided on the list below, please add your information.
            </Text>
        </Animated.View>
        <View style={{flexDirection: 'row',justifyContent: 'center', alignItems: 'center', width: FSS.width,}}> 
        <View style={{}}>

          <View style={{flexDirection: 'row', justifyContent: 'space-evenly', paddingBottom: 50}}>
            <View style={{paddingRight: 20}}>
              <Text style={{fontSize: 15 * FS, color: 'white', paddingBottom: 10, textDecorationLine: 'underline'}}>
                Locations
              </Text>
              <Text style={{fontSize: 15 * FS, color: '#e6e6e6'}}>
                Charleston, SC
              </Text>
            </View>

            <View style={{}}>
              <Text style={{fontSize: 15 * FS, color: 'white', paddingBottom: 10,textDecorationLine: 'underline'}}>
                Coming soon...
              </Text>
              <Text style={{fontSize: 15 * FS, color: '#e6e6e6'}}>
                Wilmington, NC
              </Text>
              <Text style={{fontSize: 15 * FS, color: '#e6e6e6'}}>
                Charlotte, NC
              </Text>
              <Text style={{fontSize: 15 * FS, color: '#e6e6e6'}}>
                Raleigh, NC
              </Text>
              <Text style={{fontSize: 15 * FS, color: '#e6e6e6'}}>
                Greensboro, NC
              </Text>
            </View>
          </View>
       
        </View>
        {/* Beginning of Bring Ludo to me and subtitle */}
        <View style={{paddingBottom: 50, justifyContent: 'center', alignItems: 'center', padding: 20, marginLeft: FS * 50}}>
          {/* <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: 'white',textAlign: 'center', paddingBottom: 10 }}>Bring Ludo to me!</Text> */}
          {/* <Text style={{fontSize: 15 * FS,  color: '#e6e6e6', width: FSS.width < 400 ? FSS.width : 600, maxWidth: 1550 ,textAlign: 'center', paddingLeft: 20, paddingRight: 20 }}>
            The more people that sign up in an area, the quicker Ludo will arrive!
          </Text> */}
          {/* Beginning of Bring Ludo to me form */}
          <View style={{paddingBottom: 50,}}>
            <BringLudoToMeForm/>
          </View>
          {/* End of Bring Ludo to me form */}
        </View>
        {/* End of Bring Ludo to me and subtitle */}
      </View>
      </View>
      </View>

      :

      <View style={{justifyContent: 'center', alignItems: 'center', width: FSS.width, backgroundColor: '#2E313E', alignSelf: 'center'}}> 
        <View>
        <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: 'white', marginBottom: 10, textAlign: 'center', paddingTop: 25}}>Is Ludo near you?</Text>
        <Animated.View style={{justifyContent: 'center', alignItems: 'center'}}>
            <Text style={{fontSize: 15 * FS,  color: '#e6e6e6', width: FSS.width -50 ,textAlign: 'justify', paddingBottom: 25}}>
             If there is a location you would like to see on Ludo that is not 
              provided on the list below, please add your information.
            </Text>
        </Animated.View>
        <View style={{}}>

          <View style={{flexDirection: 'row', justifyContent: 'space-evenly', paddingBottom: 25}}>
            <View style={{paddingRight: 20}}>
              <Text style={{fontSize: 15 * FS, color: 'white', paddingBottom: 10, textDecorationLine: 'underline'}}>
                Locations
              </Text>
              <Text style={{fontSize: 15 * FS, color: '#e6e6e6'}}>
                Charleston, SC
              </Text>
            </View>

            <View style={{}}>
              <Text style={{fontSize: 15 * FS, color: 'white', paddingBottom: 10,textDecorationLine: 'underline'}}>
                Coming soon...
              </Text>
              <Text style={{fontSize: 15 * FS, color: '#e6e6e6'}}>
                Wilmington, NC
              </Text>
              <Text style={{fontSize: 15 * FS, color: '#e6e6e6'}}>
                Charlotte, NC
              </Text>
              <Text style={{fontSize: 15 * FS, color: '#e6e6e6'}}>
                Raleigh, NC
              </Text>
              <Text style={{fontSize: 15 * FS, color: '#e6e6e6'}}>
                Greensboro, NC
              </Text>
            </View>
          </View>

          
        </View>
        {/* Beginning of Bring Ludo to me and subtitle */}
        <View style={{paddingBottom: 50, justifyContent: 'center', alignItems: 'center', padding: 20}}>
          {/* <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: 'white',textAlign: 'center', paddingBottom: 10 }}>Bring Ludo to me!</Text> */}
          {/* <Text style={{fontSize: 15 * FS,  color: '#e6e6e6', width: FSS.width < 400 ? FSS.width : 600, maxWidth: 1550 ,textAlign: 'center', paddingLeft: 20, paddingRight: 20 }}>
            The more people that sign up in an area, the quicker Ludo will arrive!
          </Text> */}
          {/* Beginning of Bring Ludo to me form */}
          <View style={{paddingBottom: 50,}}>
            <BringLudoToMeForm/>
          </View>
          {/* End of Bring Ludo to me form */}
        </View>
        {/* End of Bring Ludo to me and subtitle */}
      </View>
      </View>
      }
      {/* End of Is Ludo near you? */}

 
      {/* <View style={{backgroundColor: '#2E313E', width: FSS.width, justifyContent: 'center', alignItems: 'center' }}> */}


         <View style={{flexDirection: 'row',justifyContent: 'center', alignItems: 'center', }}> 

           <View style={{paddingBottom: 30, justifyContent: 'center', alignItems: 'center'}}>
             <Text style={{fontSize: 30 * FS, fontWeight: 'bold', color: 'white', paddingBottom: 10, textAlign:'center', maxWidth: smallScreen ? FSS.width-25 : 1000}}>Add your establishment to Ludo</Text>
             <Text style={{fontSize: 15 * FS,  color: '#e6e6e6', width: FSS.width < 400 ? FSS.width : 600, maxWidth: smallScreen ?  FSS.width-25  : 800 ,textAlign: 'justify', paddingBottom: 40, paddingLeft: 20, paddingRight: 20}}>
              Contact us to learn about how Ludo can help increase foot traffic to your establishment. Lets get Ludo users to your business! 
             </Text>
             <AddBarToLudoForm/>
           </View>

           <Image
             source={require('../assets/singleSim2.png')}
             style={{width: smallScreen ? 0 : 350, height: smallScreen ? 0 : 800, left: 20}}
           />

         </View>


       {/* </View> */}


       </View>


      <Footer switchTabs={switchTabs}/>
      

    </View>
  );
}


const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 100 * fontScale, 
    color: '#F60081', 
  },
  name: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 30 * fontScale,  
    color: 'white',
    padding: 5,
  },
  button: {
    paddingRight: 20,
    paddingLeft: 20,
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eafbf2',
    borderRadius: 30,
    top: 25
  },
  buttonSong: {
    width: 375,
    height: 50,
    padding: 10,
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 30,
    flexDirection: 'row',
    marginTop: 20,
    alignSelf :'center'
  },
  buttonText: {
    fontSize: 15,
    fontFamily: 'CG-Times Bold', 
  },
  buttonSongText: {
    fontSize: 20,
    fontFamily: 'CG-Times Bold', 
    marginLeft: 15
  },
  email: {
    color: 'white', 
    fontFamily: 'CG-Times Bold', 
    position: 'absolute', 
    bottom: 25, 
    fontSize: 17
  }
});
