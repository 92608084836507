import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, Dimensions, Pressable } from 'react-native';
import Home from './Tabs/Home';
import Contact from './Tabs/Contact';
import About from './Tabs/About'
import TopHeader from './TopHeader';
import WebsiteHeader from './WebsiteHeader';
import WebsiteTabs from './WebsiteTabs';
import HamburgerMenu from './HamburgerMenu';
import {isMobile} from 'react-device-detect';
import Modal from "react-native-modal";
import FadeInText from './common/FadeInText';
import HamburgerMenuExpanded from './HamburgerMenuExpanded';
import { ScrollView } from 'react-native-web';
import Wave from './Wave';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function RootScreen() {
    const [tab, setTab] = useState('HomeScreen')
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [menuActivated, setMenuActivated] = useState(false)
    const [headerTriggerd, setHeaderTriggered] = useState();
    const [isFirstRender, setIsFirstRender] = useState(true)
    const [imageOrVideo, setImageOrVideo] = useState(true)
    const [privacyPolicy, setPrivacyPolicy] = useState(false)



    function handleWindowSizeChange() {
        setWidth( Dimensions.get('window').width);
        setHeight(Dimensions.get('window').height);
    }

    

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    // useEffect(() => {
    //     console.log(width)
    // },[width])

    function switchTabs(screen, fromMenu, fromHeader, privacyPolicy){
        setTab(screen)
        if(privacyPolicy) setPrivacyPolicy(true); else setPrivacyPolicy(false)
        if(fromMenu) setMenuActivated(false);
        if(fromHeader) setHeaderTriggered(true);
    }
  return (
    <View style={styles.container}>
        {(menuActivated && width < 900) && 
          <HamburgerMenuExpanded 
            switchTabs={switchTabs} 
            width={width} 
            height={height}
            setImageOrVideo={setImageOrVideo}
            tab={tab}
          />
        }
        <TopHeader switchTabs={switchTabs} width={width} headerTriggerd={headerTriggerd} setImageOrVideo={setImageOrVideo}
          setHeaderTriggered={setHeaderTriggered}  menuActivated={menuActivated} setMenuActivated={setMenuActivated} />
        <ScrollView style={{flex: 1, marginTop: 100,}}>
        {tab === 'HomeScreen' && <Home isFirstRender={isFirstRender} setIsFirstRender={setIsFirstRender} imageOrVideo={imageOrVideo} switchTabs={switchTabs}/>}
        {tab === 'ContactScreen' && <Contact/>}
        {tab === 'AboutScreen' && <About privacyPolicy={privacyPolicy}/>}
        </ScrollView> 

        
        {/* <WebsiteHeader switchTabs={switchTabs}/>
        {width < 900 ? 
        <HamburgerMenu menuActivated={menuActivated} setMenuActivated={setMenuActivated}/> 
        : 
        <WebsiteTabs 
          headerTriggerd={headerTriggerd} 
          setHeaderTriggered={setHeaderTriggered} 
          switchTabs={switchTabs}
        />
        } */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(33,38,50,255)',//end night ideas. - fuck this stupid background color difference
  },                                      //                 - add section about Getting your Bar on Ludo 
  fadeText: {                             //                 - add screenshots frmo bar profile page or bar login
    flex: 5,
    alignItems: 'center',                 // oh and dont forget about iphone carousel scroll being stupid
    justifyContent: 'center'
  }
});
